define("discourse/plugins/swapd-topic-promo/discourse/initializers/topic-promo", ["exports", "discourse/lib/plugin-api", "discourse/models/composer", "discourse/lib/ajax", "discourse-common/lib/icon-library"], function (_exports, _pluginApi, _composer, _ajax, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initWithApi(api) {
    const siteSettings = api.container.lookup("site-settings:main");
    if (!siteSettings.topic_promo_enabled) {
      return;
    }
    api.modifyClass("component:topic-list-item", {
      pluginId: "swapd-topic-promo",
      classNameBindings: ["topic.topic_promoted", "topic.topic_promoted_in_group", "topic.closed"]
    });
    const currentUser = api.getCurrentUser();
    if (!currentUser || !currentUser.admin) {
      return;
    }
    api.attachWidgetAction("topic-admin-menu", "toggleHighlight", function () {
      const highlighted = this.attrs.topic.topic_highlighted;
      (0, _ajax.ajax)(`/t/${this.attrs.topic.id}/highlight`, {
        type: "POST",
        data: {
          highlighted: !!!highlighted
        }
      }).then(result => {
        this.attrs.topic.set("topic_highlighted", result.highlighted);
      });
    });
    api.decorateWidget("topic-admin-menu:adminMenuButtons", dec => {
      const highlighted = dec.attrs.topic.topic_highlighted;
      return {
        icon: highlighted ? "volume-mute" : "volume-up",
        fullLabel: `topic_promo.${highlighted ? "remove_highlight" : "highlight_topic"}`,
        action: "toggleHighlight"
      };
    });
  }
  var _default = _exports.default = {
    name: "topic-promo",
    initialize: () => {
      (0, _pluginApi.withPluginApi)("0.8.6", initWithApi);
    }
  };
});